/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "@/services/base.service";

const resource = "equipos";

export const ObtenerEquipos = async (idCondo: number): Promise<any> => {
  return await axios.get(`${resource}/${idCondo}`);
};

export const RegistrarEquipo = async (
  idCondo: number,
  data: any
): Promise<any> => {
  return await axios.post(`${resource}/${idCondo}`, data);
};

export const ActualizarEquipo = async (
  idCondo: number,
  idEquipo: number,
  data: any
): Promise<any> => {
  return await axios.put(`${resource}/${idCondo}/${idEquipo}`, data);
};

export const EliminarEquipo = async (
  idCondo: number,
  idEquipo: number
): Promise<any> => {
  return await axios.delete(`${resource}/${idCondo}/${idEquipo}`);
};

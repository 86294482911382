/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Mantenimiento from "../views/Mantenimiento.vue";
import axios from "@/services/base.service";
import { Jwt } from "@/interfaces/jwtPayload";
import jwtDecode from "jwt-decode";
Vue.use(VueRouter);

const Guard = (
  to: any,
  from: any,
  next: (arg?: string | undefined) => void
) => {
  const jwt = localStorage.getItem("token");
  const loggedIn = Boolean(jwt);
  const { token } = to.query;

  if (!loggedIn && !token) {
    return next("/not-found");
  } else {
    try {
      const data = jwtDecode(jwt || token) as Jwt;
      const { exp } = data;
      const isExpired = new Date().getTime() / 10000 > exp;

      if (isExpired) return next("/not-found");
      localStorage.setItem("token", token || jwt);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token || jwt}`;
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      next();
    } catch (error) {
      const { message }: any = error;
      console.error("Err", message);
      return next("/not-found");
    }

    next();
  }

  // const { data: { isAdmin } } = (data as any);
};

const routes: Array<RouteConfig> = [
  {
    path: "/not-found",
    name: "NotFound",
    component: () => import("../views/NotFound.vue")
  },
  {
    path: "/",
    name: "Mantenimiento",
    beforeEnter: Guard,
    component: Mantenimiento
  },
  {
    path: "/mantenimiento/:evento",
    name: "Evento",
    props: true,
    beforeEnter: Guard,
    component: () => import("../views/Evento.vue")
  },
  {
    path: "/equipos",
    name: "Equipos",
    beforeEnter: Guard,
    component: () => import("../views/Equipos.vue")
  },
  {
    path: "/mantenedores",
    name: "Mantenedores",
    beforeEnter: Guard,
    component: () => import("../views/Mantenedores.vue")
  },
  {
    path: "/programacion-eventos",
    name: "ProgramacionEventos",
    beforeEnter: Guard,
    component: () => import("../views/ProgramacionEventos.vue")
  },
  {
    path: "/programacion-eventos/:evento",
    name: "EventoProgramados",
    props: true,
    beforeEnter: Guard,
    component: () => import("../views/EventosProgramados.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

import Vue from "vue";
import Vuex from "vuex";

//MODULOS
import appState from "@/store/modules/appState";
import eventoState from "@/store/modules/eventoState";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    appState,
    eventoState
  }
});

import axios from "axios";

const baseURL = process.env.VUE_APP_API;
const baseApi = axios.create({
  baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*"
  }
});

export default baseApi;



































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { State, Action } from "vuex-class";

@Component
export default class MantenimientoSinCondominio extends Vue {
  @State(({ appState }) => appState.condominios) condominios!: Array<any>;
  @State(({ appState }) => appState.condominio) condominio!: any;
  @Action definirCondominio!: Function;
  @Action registrarCondominio!: Function;

  @Validate({ required }) nuevoCondominio = "";
  @Validate({ required }) rut = "";

  public dialogCondo = false;

  private seleccionarCondominio(condo: string): void {
    this.definirCondominio({ condominio: condo });
  }

  private agregarCondominio(): void {
    const nuevoCondominio = {
      nombre: this.nuevoCondominio,
      rut: this.rut
    };
    this.registrarCondominio(nuevoCondominio);
    this.dialogCondo = false;
    this.clearForm();
  }

  //LIMPIAR FORM
  private clearForm(): void {
    this.$v.$reset();
    this.nuevoCondominio = "";
    this.rut = "";
  }

  //HABILITAR SUBMIT
  get isDisabled(): boolean {
    return !this.rut || !this.nuevoCondominio ? true : false;
  }

  //VALIDACIONES
  get nuevoCondominioErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nuevoCondominio.$dirty) return errors;
    !this.$v.nuevoCondominio.required && errors.push("El campo es requerido");
    return errors;
  }
  get rutErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.rut.$dirty) return errors;
    !this.$v.rut.required && errors.push("El campo es requerido");
    return errors;
  }
}

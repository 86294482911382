/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "@/services/base.service";

const resource = "gastos";

export const RegistrarGasto = async (
  idEvento: number,
  data: any
): Promise<any> => {
  // const jwt = localStorage.getItem("jwt")
  // const header = {headers:{ Authorization: "Bearer " + jwt }};
  return await axios.post(`${resource}/${idEvento}`, data);
};

export const ActualizarGasto = async (
  idEvento: number,
  idGasto: number,
  data: any
): Promise<any> => {
  // const jwt = localStorage.getItem("jwt")
  // const header = {headers:{ Authorization: "Bearer " + jwt }};
  return await axios.put(`${resource}/${idEvento}/${idGasto}`, data);
};

export const EliminarGasto = async (
  idEvento: number,
  idGasto: number
): Promise<any> => {
  // const jwt = localStorage.getItem("jwt")
  // const header = {headers:{ Authorization: "Bearer " + jwt }};
  return await axios.delete(`${resource}/${idEvento}/${idGasto}`);
};










































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Watch } from "vue-property-decorator";
import { State, Action } from "vuex-class";
@Component
export default class MantenimientoCalendario extends Vue {
  @State(({ appState }) => appState.condominio) condominio!: any;
  @State(({ appState }) => appState.eventos) eventos!: Array<any>;
  @Action obtenerEventos!: Function;
  public type = "month";
  public weekday: Array<number> = [0, 1, 2, 3, 4, 5, 6];
  public value = "";
  public events: Array<any> = [];
  public selectedEvent = {};
  public selectedElement = null;
  public selectedOpen = false;

  public mostrarEventoIndividual(id: any): void {
    // const toSlug = value.toLowerCase().replace(/ /gi, "-");
    this.$router.push(`/mantenimiento/${id}`);
  }
  // mounted() {
  //   this.obtenerEventos(this.condominio.id);
  // }

  @Watch("eventos", {
    immediate: true,
    deep: true
  })
  actualizarEventos() {
    this.getEvents();
  }

  showEvent({ nativeEvent, event }: any) {
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      setTimeout(() => {
        this.selectedOpen = true;
      }, 10);
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      setTimeout(open, 10);
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  get eventosSinEliminados(): Array<any> {
    return this.eventos.filter(evento => evento.estado !== "Eliminado");
  }

  public getEvents() {
    const eventosCalendario = this.eventosSinEliminados.map((evento: any) => ({
      id: evento.id,
      color: this.obtenerPrioridadColor(evento.prioridad),
      start: new Date(`${evento.fecha.replaceAll("/", "-")}T00:00:00`),
      name: evento.equipo.nombre,
      // end: evento.culminacion
      //   ? new Date(`${evento.culminacion}T00:00:00`)
      //   : null,
      descripcion: evento.notaDescripcion,
      estado: evento.estado
    }));
    this.events = eventosCalendario;
  }
  public setToday() {
    this.value = "";
  }
  public getEventColor(event: any) {
    return event.color;
  }
  public obtenerPrioridadColor(item: string) {
    if (item === "Baja") return "yellow darken-3";
    if (item === "Normal") return "blue darken-3";
    if (item === "Alta") return "red darken-3";
  }
  public obtenerEstadoColor(item: string): string {
    if (item === "Por hacer") return "orange lighten-1";
    else if (item === "Completado") return "green lighten-1";
    else return "grey lighten-1";
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "@/services/base.service";

const resource = "areas";

export const ObtenerAreas = async (idCondo: number): Promise<any> => {
  return await axios.get(`${resource}/${idCondo}`);
};

export const RegistrarArea = async (
  idCondo: number,
  data: any
): Promise<any> => {
  return await axios.post(`${resource}/${idCondo}`, data);
};
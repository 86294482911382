/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "@/services/base.service";

const resource = "condominios";

export const ObtenerCondominios = async (): Promise<any> => {
  // const jwt = localStorage.getItem("jwt")
  // const header = {headers:{ Authorization: "Bearer " + jwt }};
  return await axios.get(`${resource}/me`);
};

export const RegistrarCondominio = async (data: any): Promise<any> => {
  // const jwt = localStorage.getItem("jwt")
  // const header = {headers:{ Authorization: "Bearer " + jwt }};
  console.info(data, "desde condominio servicio");
  return await axios.post(`${resource}`, data);
};
















































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { State, Action } from "vuex-class";

@Component
export default class MantenimientoForm extends Vue {
  @State(({ appState }) => appState.condominio) condominio!: any;
  @State(({ appState }) => appState.prioridades) prioridades!: Array<string>;
  @State(({ appState }) => appState.estado) estado!: Array<string>;
  @State(({ appState }) => appState.eventos) eventos!: Array<any>;
  @State(({ appState }) => appState.equipos) equipos!: Array<any>;
  @State(({ appState }) => appState.areas) areas!: Array<any>;
  @State(({ appState }) => appState.mantenedores) mantenedores!: Array<any>;
  @State(({ appState }) => appState.tipos) tipos!: Array<string>;
  @State(({ appState }) => appState.certificaciones) certificaciones!: Array<
    string
  >;
  @Action definirEventos!: Function;
  @Action registrarEvento!: Function;

  @Validate({ required }) fecha: any = null;
  @Validate({ required }) equipo: any = null;
  @Validate({ required }) mantenedor: any = null;
  @Validate({ required }) prioridad: any = null;
  @Validate({ required }) tipo: any = null;
  @Validate({ required }) descripcion: any = null;
  @Validate({ required }) certificacion: any = null;
  @Validate({ required }) area: any = null;
  public isDatePicker = false;

  //REGISTRAR NUEVO MANTENEDOR
  private register(): void {
    const evento = {
      fecha: this.fecha,
      area_id: this.area.id,
      equipo_id: this.equipo.id,
      mantenedor_id: this.mantenedor.id,
      estado: "Por hacer",
      prioridad: this.prioridad,
      tipo: this.tipo,
      notaDescripcion: this.descripcion,
      certificacion: this.certificacion
    };

    console.info(evento);
    this.registrarEvento({ condoId: this.condominio.id, data: evento });
    this.clearForm();
    this.$emit("cerrarForm", false);
  }

  //LIMPIAR FORM
  private clearForm(): void {
    this.$v.$reset();
    this.fecha = null;
    this.area = null;
    this.equipo = null;
    this.mantenedor = null;
    this.prioridad = null;
    this.tipo = null;
    this.descripcion = null;
    this.certificacion = null;
  }

  //HABILITAR SUBMIT
  get isDisabled(): boolean {
    return !this.mantenedor ||
      !this.descripcion ||
      !this.prioridad ||
      !this.equipo ||
      !this.tipo ||
      !this.fecha ||
      !this.area ||
      (this.tipo === "Certificacion" && !this.certificacion)
      ? true
      : false;
  }

  //VALIDAR QUE LA FECHA SELECCIONADA SEA SUPERIOR A HOY
  get validarFecha(): boolean {
    const hoy = new Date();
    const reprogramacion = new Date(`${this.fecha}T00:00:00`);
    return reprogramacion >= hoy ? true : false;
  }

  //VALIDACIONES
  get fechaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fecha.$dirty) return errors;
    !this.$v.fecha.required && errors.push("El campo es requerido");
    return errors;
  }
  get equipoErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.equipo.$dirty) return errors;
    !this.$v.equipo.required && errors.push("El campo es requerido");
    return errors;
  }
  get areaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.area.$dirty) return errors;
    !this.$v.area.required && errors.push("El campo es requerido");
    return errors;
  }
  get mantenedorErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.mantenedor.$dirty) return errors;
    !this.$v.mantenedor.required && errors.push("El campo es requerido");
    return errors;
  }
  get prioridadErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.prioridad.$dirty) return errors;
    !this.$v.prioridad.required && errors.push("El campo es requerido");
    return errors;
  }
  get tipoErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipo.$dirty) return errors;
    !this.$v.tipo.required && errors.push("El campo es requerido");
    return errors;
  }
  get certificacionErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.certificacion.$dirty) return errors;
    !this.$v.certificacion.required && errors.push("El campo es requerido");
    return errors;
  }
  get descripcionErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.descripcion.$dirty) return errors;
    !this.$v.descripcion.required && errors.push("El campo es requerido");
    return errors;
  }
}















































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";
import MantenimientoCalendario from "@/components/Mantenimiento/MantenimientoCalendario.vue";
import MantenimientoTabla from "@/components/Mantenimiento/MantenimientoTabla.vue";
import MantenimientoForm from "@/components/Mantenimiento/MantenimientoForm.vue";
import MantenimientoSinCondominio from "@/components/Mantenimiento/MantenimientoSinCondominio.vue";

import { State, Action } from "vuex-class";

@Component({
  components: {
    MantenimientoCalendario,
    MantenimientoTabla,
    MantenimientoForm,
    MantenimientoSinCondominio
  }
})
export default class Mantenimiento extends Vue {
  @State(({ appState }) => appState.condominios) condominios!: Array<any>;

  get tieneCondominios(): boolean {
    return this.condominios.length > 0 ? true : false;
  }

  //NOMBRE DEL COMPONENTE ACTUAL A SER MOSTRADO EN EL <component>
  public componenteActual = "Calendario";

  //MODELO PARA MOSTRAR O OCULTAR EL FORMULARIO LATERAL
  public drawer = false;

  //ASIGNA AL COMPONENT :is EL COMPONENTE QUE SE DESAEA MOSTRAR
  get componenteSeleccionado(): string {
    return `Mantenimiento${this.componenteActual}`;
  }

  //EVENTO EMITIDO DESDE MantenimientoForm PARA CERRAR EL FORMULARIO LATERAL
  public cerrarDrawer(value: boolean): void {
    this.drawer = value;
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "@/services/base.service";

const resource = "programaciones";

export const ObtenerProgramaciones = async ({tipo, desde, hasta}): Promise<any> => {
	const params = {
		type: tipo,
		from: desde,
		until: hasta
	}
  return await axios.get(resource, { params });
};

// export const RegistrarEvento = async (
//   idCondo: number,
//   data: any
// ): Promise<any> => {
//   return await axios.post(`${resource}/${idCondo}`, data);
// };

// export const ActualizarEvento = async (
//   idCondo: number,
//   idEvento: number,
//   data: any
// ): Promise<any> => {
//   return await axios.put(`${resource}/${idCondo}/${idEvento}`, data);
// };

// export const ObtenerHisotrialDeEquipo = async (
//   idCondo: number,
//   idEquipo: number
// ): Promise<any> => {
//   return await axios.get(`${resource}/${idCondo}/${idEquipo}`);
// };

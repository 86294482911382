



















































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";

type Headers = {
  text: string;
  align?: string;
  value: string;
  sortable?: boolean;
};
type Desserts = {
  fecha: string;
  equipo: any;
  mantenedor: any;
  prioridad: string;
  estado: string;
  retraso: string;
  culminacion: string;
  tipo: string;
};

@Component
export default class MantenimientoLista extends Vue {
  public headers: Array<Headers> = [
    {
      text: "FECHA",
      align: "center",
      sortable: true,
      value: "fecha"
    },
    {
      text: "EQUIPO/ZONA",
      align: "center",
      sortable: true,
      value: "equipo"
    },
    {
      text: "MANTENEDOR",
      align: "center",
      sortable: true,
      value: "mantenedor"
    },
    {
      text: "PRIORIDAD",
      align: "center",
      sortable: true,
      value: "prioridad"
    },
    {
      text: "ESTADO",
      align: "center",
      sortable: true,
      value: "estado"
    },
    {
      text: "RETRASO",
      align: "center",
      sortable: true,
      value: "retraso"
    },
    {
      text: "FECHA DE CULMINACIÓN",
      align: "center",
      sortable: true,
      value: "culminacion"
    },
    {
      text: "TIPO",
      align: "center",
      sortable: true,
      value: "tipo"
    }
  ];
  @State(({ appState }) => appState.condominio) condominio!: any;
  @State(({ appState }) => appState.eventos) eventos!: Array<any>;

  get eventosSinEliminados(): Array<Desserts> {
    return this.eventos.filter(evento => evento.estado !== "Eliminado");
  }

  public formatearFecha(fecha: string): string {
    const fechaAString = `${fecha.replaceAll("/", "-")}T00:00:00`;
    return `${new Date(fechaAString).toLocaleDateString()}`;
  }
  public mostrarEvento(value: any): void {
    this.$router.push(`/mantenimiento/${value.id}`);
  }
  public obtenerEstadoColor(item: string): string {
    if (item === "Por hacer") return "orange lighten-1";
    else if (item === "Completado") return "green lighten-1";
    else return "grey lighten-1";
  }
  public obtenerPrioridadColor(item: string): string {
    if (item === "Baja") return "prioridad-baja";
    else if (item === "Normal") return "prioridad-normal";
    else return "prioridad-alta";
  }
  public definirRetraso(fecha: any): any {
    const hoy = new Date().getTime();
    const fechaEstablecida = new Date(fecha).getTime();
    const retraso = Math.floor((hoy - fechaEstablecida) / 86400000);
    if (retraso >= 0) {
      return retraso === 1 ? `${retraso} día` : `${retraso || "0"} días`;
    }
    return "0 días";
  }
}

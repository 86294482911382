/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "@/services/base.service";

const resource = "mantenedores";

export const ObtenerMantenedores = async (idCondo: number): Promise<any> => {
  return await axios.get(`${resource}/${idCondo}`);
};

export const RegistrarMantenedor = async (
  idCondo: number,
  data: any
): Promise<any> => {
  return await axios.post(`${resource}/${idCondo}`, data);
};

export const ActualizarMantenedor = async (
  idCondo: number,
  idMantenedor: number,
  data: any
): Promise<any> => {
  return await axios.put(`${resource}/${idCondo}/${idMantenedor}`, data);
};

export const EliminarMantenedor = async (
  idCondo: number,
  idMantenedor: number
): Promise<any> => {
  return await axios.delete(`${resource}/${idCondo}/${idMantenedor}`);
};

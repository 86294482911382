/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  RegistrarCondominio,
  ObtenerCondominios
} from "@/services/condondominios.service";
import {
  ObtenerMantenedores,
  RegistrarMantenedor,
  ActualizarMantenedor,
  EliminarMantenedor
} from "@/services/mantenedores.service";
import {
  ObtenerEquipos,
  RegistrarEquipo,
  ActualizarEquipo,
  EliminarEquipo
} from "@/services/equipos.service";
import { ObtenerAreas, RegistrarArea } from "@/services/areas.service";
import {
  ObtenerEventos,
  RegistrarEvento,
  /*   ActualizarEvento, */
  ObtenerHisotrialDeEquipo
} from "@/services/eventos.service";
import { ObtenerProgramaciones } from "@/services/programaciones.service";
import { RegistrarAdjunto, ObtenerAdjuntos } from "@/services/adjuntos.service";

import Vue from "vue";

const appState = {
  state: () => ({
    equipos: [],
    historial: [],
    condominios: [],
    condominio: {},
    mantenedores: [],
    eventos: [],
    programaciones: [],
    eventosProgramados: [],
    areas: [],
    adjuntos: [],
    estados: ["Por hacer", "Completado", "Cancelado", "Eliminado"],
    prioridades: ["Baja", "Normal", "Alta"],
    tipos: ["Mantenimiento", "Reparación", "Certificacion"],
    frecuencias: [
      { nombre: "semanal", unidad: 1, tipo: "weeks" },
      { nombre: "quincenal", unidad: 15, tipo: "days" },
      { nombre: "mensual", unidad: 1, tipo: "months" },
      { nombre: "bimensual", unidad: 2, tipo: "months" },
      { nombre: "trimestral", unidad: 3, tipo: "months" },
      { nombre: "cuatrimestral", unidad: 4, tipo: "months" },
      { nombre: "semestral", unidad: 6, tipo: "months" },
      { nombre: "anual", unidad: 1, tipo: "years" }
    ],
    certificaciones: ["Ascensores", "Extintores", "Sello Verde Gas"],
    ultimaQuery: {}
  }),
  mutations: {
    /* MUTACIONES DE CONDOMINIOS */
    obtenerCondominios(state: any, payload: any): void {
      state.condominios = payload;
    },
    definirCondominio(state: any, payload: any): void {
      state.condominio = payload.condominio;
    },

    /* MUTACIONES DE MANTENEDORES */
    obtenerMantenedores(state: any, payload: any): void {
      state.mantenedores = payload;
    },

    /* MUTACIONES DE EQUIPOS */
    obtenerEquipos(state: any, payload: any): void {
      state.equipos = payload;
    },
    obtenerHistorialDeEquipo(state: any, payload: any): void {
      state.historial = payload;
    },
    limpiarHistorialDeEquipo(state: any, payload: any): void {
      state.historial = payload;
    },

    /* MUTACIONES DE AREAS */
    obtenerAreas(state: any, payload: any): void {
      state.areas = payload;
    },

    /* MUTACIONES DE EVENTOS */
    obtenerEventos(state: any, payload: any): void {
      state.eventos = payload;
    },

    /* MUTACIONES DE PROGRAMACIONES */
    obtenerProgramaciones(state: any, payload: any): void {
      state.programaciones = payload;
    },
    obtenerEventosProgramados(state: any, payload: any): void {
      state.eventosProgramados = payload;
    },
    establecerUltimaQuery(state: any, payload: any): void {
      state.ultimaQuery = payload;
    },
    limpiarProgramaciones(state: any, payload: any): void {
      state.programaciones = payload;
    },

    /* MUTACIONES DE ADJUNTOS */
    obtenerAdjuntos(state: any, payload: any): void {
      state.adjuntos = payload;
    }
  },
  actions: {
    /* ACCIONES DE CONDOMINIOS */
    async obtenerCondominios({ commit }: any): Promise<any> {
      const loader = Vue.$loading.show();
      try {
        const { data } = await ObtenerCondominios();
        commit("obtenerCondominios", data.data.rows);
        loader.hide();
      } catch (error) {
        console.info(error);
        loader.hide();
      }
    },
    async registrarCondominio({ dispatch }: any, payload: any): Promise<void> {
      try {
        const condominio = await RegistrarCondominio(payload);
        if (condominio.status === 200) {
          Vue.$toast.success(`Condominio registrado con éxito`);
          dispatch("obtenerCondominios");
        }
      } catch (error) {
        console.info(error);
        Vue.$toast.error(`Ha ocurrido un error, intenta de nuevo`);
      }
    },
    definirCondominio({ commit, dispatch }: any, payload: any): void {
      commit("definirCondominio", payload);
      dispatch("limpiarHistorialDeEquipo");
      dispatch("obtenerMantenedores", payload.condominio.id);
      dispatch("obtenerEquipos", payload.condominio.id);
      dispatch("obtenerEventos", payload.condominio.id);
      dispatch("obtenerAreas", payload.condominio.id);
      dispatch("limpiarProgramaciones");
    },

    /* ACCIONES DE PROGRAMACIONES */
    async obtenerProgramaciones(
      { commit }: any,
      { tipo, desde, hasta }
    ): Promise<any> {
      const loader = Vue.$loading.show();
      try {
        const { data } = await ObtenerProgramaciones({ tipo, desde, hasta });
        commit("obtenerProgramaciones", data?.data);
        loader.hide();
      } catch (error) {
        console.info(error);
        loader.hide();
      }
    },

    /* ACCIONES DE MANTENEDORES */
    async obtenerMantenedores({ commit }: any, idCondo: number): Promise<any> {
      const loader = Vue.$loading.show();
      try {
        const { data } = await ObtenerMantenedores(idCondo);
        commit("obtenerMantenedores", data.data);
        loader.hide();
      } catch (error) {
        console.info(error);
        loader.hide();
      }
    },
    async registrarMantenedor({ dispatch }: any, payload: any): Promise<void> {
      const { condoId, data } = payload;
      try {
        const mantenedor = await RegistrarMantenedor(condoId, data);
        if (mantenedor.status === 200) {
          Vue.$toast.success(`Mantenedor registrado con éxito`);
          dispatch("obtenerMantenedores", condoId);
        }
      } catch (error) {
        console.info(error);
        Vue.$toast.error(`Ha ocurrido un error, intenta de nuevo`);
      }
    },
    async actualizarMantenedor({ dispatch }: any, payload: any): Promise<void> {
      const { idCondo, idMantenedor, data } = payload;
      try {
        const mantenedor = await ActualizarMantenedor(
          idCondo,
          idMantenedor,
          data
        );
        if (mantenedor.status === 200) {
          Vue.$toast.success(`Mantenedor actualizado con éxito`);
          dispatch("obtenerMantenedores", idCondo);
        }
      } catch (error) {
        console.info(error);
        Vue.$toast.error(`Ha ocurrido un error, intenta de nuevo`);
      }
    },
    async eliminarMantenedor({ dispatch }: any, payload: any): Promise<void> {
      const { idCondo, idMantenedor } = payload;
      try {
        const mantenedor = await EliminarMantenedor(idCondo, idMantenedor);
        if (mantenedor.status === 200) {
          Vue.$toast.success(`Mantenedor eliminado con éxito`);
          dispatch("obtenerMantenedores", idCondo);
        }
      } catch (error) {
        console.info(error);
        Vue.$toast.error(`Ha ocurrido un error, intenta de nuevo`);
      }
    },

    /* ACCIONES DE EQUIPOS */
    async obtenerEquipos({ commit }: any, idCondo: number): Promise<any> {
      const loader = Vue.$loading.show();
      try {
        const { data } = await ObtenerEquipos(idCondo);
        commit("obtenerEquipos", data.data);
        loader.hide();
      } catch (error) {
        console.info(error);
        loader.hide();
      }
    },
    async registrarEquipo({ dispatch }: any, payload: any): Promise<void> {
      const { condoId, data } = payload;
      try {
        const equipo = await RegistrarEquipo(condoId, data);
        if (equipo.status === 200) {
          Vue.$toast.success(`Equipo registrado con éxito`);
          dispatch("obtenerEquipos", condoId);
        }
      } catch (error) {
        console.info(error);
        Vue.$toast.error(`Ha ocurrido un error, intenta de nuevo`);
      }
    },
    async actualizarEquipo({ dispatch }: any, payload: any): Promise<void> {
      const { idCondo, idEquipo, data } = payload;
      try {
        const equipo = await ActualizarEquipo(idCondo, idEquipo, data);
        if (equipo.status === 200) {
          Vue.$toast.success(`Equipo actualizado con éxito`);
          dispatch("obtenerEquipos", idCondo);
        }
      } catch (error) {
        console.info(error);
        Vue.$toast.error(`Ha ocurrido un error, intenta de nuevo`);
      }
    },
    async eliminarEquipo({ dispatch }: any, payload: any): Promise<void> {
      const { idCondo, idEquipo } = payload;
      try {
        const mantenedor = await EliminarEquipo(idCondo, idEquipo);
        if (mantenedor.status === 200) {
          Vue.$toast.success(`Equipo eliminado con éxito`);
          dispatch("obtenerEquipos", idCondo);
        }
      } catch (error) {
        console.info(error);
        Vue.$toast.error(`Ha ocurrido un error, intenta de nuevo`);
      }
    },
    async obtenerHistorialDeEquipo(
      { commit }: any,
      payload: any
    ): Promise<any> {
      const loader = Vue.$loading.show();
      const { idCondo, idEquipo } = payload;
      try {
        const { data } = await ObtenerHisotrialDeEquipo(idCondo, idEquipo);
        commit("obtenerHistorialDeEquipo", data.data);
        loader.hide();
      } catch (error) {
        console.info(error);
        loader.hide();
      }
    },
    limpiarHistorialDeEquipo({ commit }: any): void {
      commit("limpiarHistorialDeEquipo");
    },

    /* ACCIONES DE AREA */
    async obtenerAreas({ commit }: any, idCondo: number): Promise<any> {
      const loader = Vue.$loading.show();
      try {
        const { data } = await ObtenerAreas(idCondo);
        commit("obtenerAreas", data.data);
        loader.hide();
      } catch (error) {
        console.info(error);
        loader.hide();
      }
    },
    async registrarArea({ dispatch }: any, payload: any): Promise<void> {
      const { condoId, data } = payload;
      try {
        const equipo = await RegistrarArea(condoId, data);
        if (equipo.status === 200) {
          Vue.$toast.success(`Area registrada con éxito`);
          dispatch("obtenerAreas", condoId);
        }
      } catch (error) {
        console.info(error);
        Vue.$toast.error(`Ha ocurrido un error, intenta de nuevo`);
      }
    },

    /* ACCIONES DE EVENTOS */
    async obtenerEventos({ commit }: any, idCondo: number): Promise<any> {
      const loader = Vue.$loading.show();
      try {
        const { data } = await ObtenerEventos(idCondo);
        commit("obtenerEventos", data.data);
        loader.hide();
      } catch (error) {
        console.info(error);
        loader.hide();
      }
    },
    async registrarEvento({ dispatch }: any, payload: any): Promise<void> {
      const { condoId, data } = payload;
      try {
        const evento = await RegistrarEvento(condoId, data);
        if (evento.status === 200) {
          Vue.$toast.success(`Evento registrado con éxito`);
          dispatch("obtenerEventos", condoId);
        }
      } catch (error) {
        console.info(error);
        Vue.$toast.error(`Ha ocurrido un error, intenta de nuevo`);
      }
    },
    obtenerEventosProgramados({ commit }: any, eventosProgramados: any): void {
      commit("obtenerEventosProgramados", eventosProgramados);
    },
    establecerUltimaQuery({ commit }: any, ultimaQuery: any): void {
      commit("establecerUltimaQuery", ultimaQuery);
    },
    limpiarProgramaciones({ commit }: any): void {
      commit("limpiarProgramaciones");
    },

    /* ACCIONES DE ADJUNTOS */
    async obtenerAdjuntos({ commit }: any, idEvento: number): Promise<any> {
      const loader = Vue.$loading.show();
      try {
        const { data } = await ObtenerAdjuntos(idEvento);
        commit("obtenerAdjuntos", data.data);
        loader.hide();
      } catch (error) {
        console.info(error);
        loader.hide();
      }
    },
    async registrarAdjunto({ dispatch }: any, payload: any): Promise<void> {
      const { idEvento, data } = payload;
      try {
        const evento = await RegistrarAdjunto(idEvento, data);
        if (evento.status === 200) {
          Vue.$toast.success(`Adjunto registrado con éxito`);
          dispatch("obtenerAdjuntos", idEvento);
        }
      } catch (error) {
        console.info(error);
        Vue.$toast.error(`Ha ocurrido un error, intenta de nuevo`);
      }
    }
  },
  getters: {
    obtenerEvento: (state: { eventos: Array<any> }) => (id: number) => {
      const evento = state.eventos.find(evento => evento.id === id);
      return evento;
    },
    obtenerProgramacion: (state: { programaciones: Array<any> }) => (
      id: any
    ) => {
      const programacion = state.programaciones.find(
        programacion => programacion.id == id
      );
      return programacion;
    },
    obtenerTitulo: (state: { programaciones: Array<any> }) => (id: any) => {
      const evento = state.programaciones.find(evento => evento.id == id);
      const { area, equipo } = evento;
      return `${area.nombre} / ${equipo.nombre}`;
    }
  }
};
export default appState;

/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "@/services/base.service";

const resource = "emails";

export const RegistrarEmail = async (
  idEvento: number,
  data: any
): Promise<any> => {
  return await axios.post(`${resource}/${idEvento}`, data);
};

export const EliminarEmail = async (
  idEvento: number,
  idEmail: number
): Promise<any> => {
  return await axios.delete(`${resource}/${idEvento}/${idEmail}`);
};















































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Watch } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { State, Action } from "vuex-class";

@Component
export default class NavigationMobile extends Vue {
  @State(({ appState }) => appState.condominios) condominios!: Array<any>;
  @State(({ appState }) => appState.condominio) condominio!: any;
  @Action definirCondominio!: Function;
  @Action registrarCondominio!: Function;

  @Validate({ required }) nuevoCondominio = "";
  @Validate({ required }) rut = "";
  public escogerCondominio = {};
  public escogerMenuItem = { nombre: "Eventos", link: "/" };
  public dialogCondo = false;
  public dialogCondos = false;
  public dialogSubmenu = false;
  protected submenuItems = [
    { nombre: "Eventos", link: "/" },
    { nombre: "Programación de eventos", link: "/programacion-eventos" }
  ];
  protected currentMenuItem = "Eventos";

  protected changeMenuItem(item): void {
    this.currentMenuItem =
      item.nombre === "Programación de eventos" ? "Programación" : item.nombre;
    this.$route.path !== item.link && this.$router.push(item.link);
    this.dialogSubmenu = false;
  }

  @Watch("condominios", {
    immediate: true,
    deep: true
  })
  actualizarEventos() {
    this.obtenerPrimerCondominio();
  }

  private obtenerPrimerCondominio(): void {
    this.condominios.length
      ? this.seleccionarCondominio(this.condominios[0])
      : null;
  }

  private seleccionarCondominio(condo: any): void {
    console.info(this.escogerCondominio);
    this.definirCondominio({ condominio: condo });
  }

  private seleccionarCondominioMobile(): void {
    this.definirCondominio({ condominio: this.escogerCondominio });
    this.dialogCondos = false;
  }

  private agregarCondominio(): void {
    const nuevoCondominio = {
      nombre: this.nuevoCondominio,
      rut: this.rut
    };
    this.registrarCondominio(nuevoCondominio);
    this.dialogCondo = false;
    this.clearForm();
  }

  //LIMPIAR FORM
  private clearForm(): void {
    this.$v.$reset();
    this.nuevoCondominio = "";
    this.rut = "";
  }

  //HABILITAR SUBMIT
  get isDisabled(): boolean {
    return !this.rut || !this.nuevoCondominio ? true : false;
  }

  //VALIDACIONES
  get nuevoCondominioErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nuevoCondominio.$dirty) return errors;
    !this.$v.nuevoCondominio.required && errors.push("El campo es requerido");
    return errors;
  }
  get rutErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.rut.$dirty) return errors;
    !this.$v.rut.required && errors.push("El campo es requerido");
    return errors;
  }
}

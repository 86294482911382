/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "@/services/base.service";

const resource = "facturacion";

export const RegistrarFacturacion = async (
  idEvento: number,
  data: any
): Promise<any> => {
  // const jwt = localStorage.getItem("jwt")
  // const header = {headers:{ Authorization: "Bearer " + jwt }};
  return await axios.post(`${resource}/${idEvento}`, data);
};

export const ActualizarFacturacion = async (
  idEvento: number,
  idGasto: number,
  data: any
): Promise<any> => {
  // const jwt = localStorage.getItem("jwt")
  // const header = {headers:{ Authorization: "Bearer " + jwt }};
  return await axios.put(`${resource}/${idEvento}/${idGasto}`, data);
};

// export const EliminarGasto = async (
//   idEvento: number,
//   idGasto: number
// ): Promise<any> => {
//   // const jwt = localStorage.getItem("jwt")
//   // const header = {headers:{ Authorization: "Bearer " + jwt }};
//   return await axios.delete(`${resource}/${idEvento}/${idGasto}`);
// };

/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { ActualizarEvento } from "@/services/eventos.service";
import {
  RegistrarGasto,
  ActualizarGasto,
  EliminarGasto
} from "@/services/gastos.service";
import { RegistrarFacturacion } from "@/services/facturacion.service";
import { RegistrarEmail, EliminarEmail } from "@/services/email.service";

const eventoState = {
  state: () => ({
    evento: {}
  }),
  mutations: {
    definirFecha(state: any, payload: any): void {
      state.evento.fecha = payload.fecha;
    },
    definirEstado(state: any, payload: any): void {
      state.evento.estado = payload.estado;
    },
    definirPrioridad(state: any, payload: any): void {
      state.evento.prioridad = payload.prioridad;
    },
    definirMantenedor(state: any, payload: any): void {
      state.evento.mantenedor = payload.mantenedor;
    },
    definirMantenedorId(state: any, payload: any): void {
      state.evento.mantenedor_id = payload.mantenedor_id;
    },
    definirNotaDescripcion(state: any, payload: any): void {
      state.evento.notaDescripcion = payload.notaDescripcion;
    },
    definirCulminacion(state: any, payload: any): void {
      state.evento.culminacion = payload.culminacion;
    },
    definirNotaCulminacion(state: any, payload: any): void {
      state.evento.notaCulminacion = payload.notaCulminacion;
    },

    definirGastos(state: any, payload: any): void {
      state.evento.gastos = payload.gastos;
    },
    registrarGasto(state: any, payload: any): void {
      state.evento.gatos = payload;
    },

    definirFacturacion(state: any, payload: any): void {
      state.evento.facturacion = payload.facturacion;
    },
    definirEmails(state: any, payload: any): void {
      state.evento.emails = payload.emails;
    },
    definirEvento(state: any, payload: any): void {
      state.evento = payload.evento;
    }
  },
  actions: {
    /*  */
    definirFecha({ commit }: any, payload: any): void {
      commit("definirFecha", payload);
    },
    definirEstado({ commit }: any, payload: any): void {
      commit("definirEstado", payload);
    },
    definirPrioridad({ commit }: any, payload: any): void {
      commit("definirPrioridad", payload);
    },
    definirMantenedor({ commit }: any, payload: any): void {
      commit("definirMantenedor", payload);
    },
    definirMantenedorId({ commit }: any, payload: any): void {
      commit("definirMantenedorId", payload);
    },
    definirNotaDescripcion({ commit }: any, payload: any): void {
      commit("definirNotaDescripcion", payload);
    },
    definirCulminacion({ commit }: any, payload: any): void {
      commit("definirCulminacion", payload);
    },
    definirNotaCulminacion({ commit }: any, payload: any): void {
      commit("definirNotaCulminacion", payload);
    },

    // definirGastos({ commit }: any, payload: any): void {
    //   commit("definirGastos", payload);
    // },

    /* ACCIONES DEL EVENTO */
    async actualizarEvento(state: any, payload: any): Promise<void> {
      const { idCondo, idEvento, data } = payload;
      try {
        const evento = await ActualizarEvento(idCondo, idEvento, data);
        console.info(evento);
        if (evento.status === 200) {
          Vue.$toast.success(`Evento actualizado con éxito`);
        }
      } catch (error) {
        console.info(error);
        Vue.$toast.error(`Ha ocurrido un error, intenta de nuevo`);
      }
    },

    /* ACCIONES DE GASTOS */
    async registrarGasto(state: any, payload: any): Promise<void> {
      const { idEvento, data } = payload;
      try {
        const gasto = await RegistrarGasto(idEvento, data);
        if (gasto.status === 200) {
          Vue.$toast.success(`Gasto registrado con éxito`);
        }
      } catch (error) {
        console.info(error);
        Vue.$toast.error(`Ha ocurrido un error, intenta de nuevo`);
      }
    },
    async actualizarGasto(state: any, payload: any): Promise<void> {
      const { idEvento, idGasto, data } = payload;
      try {
        const gasto = await ActualizarGasto(idEvento, idGasto, data);
        if (gasto.status === 200) {
          Vue.$toast.success(`Gasto actualizado con éxito`);
        }
      } catch (error) {
        console.info(error);
        Vue.$toast.error(`Ha ocurrido un error, intenta de nuevo`);
      }
    },
    async eliminarGasto(state: any, payload: any): Promise<void> {
      const { idEvento, idGasto } = payload;
      try {
        const gasto = await EliminarGasto(idEvento, idGasto);
        if (gasto.status === 200) {
          Vue.$toast.success(`Gasto eliminado con éxito`);
        }
      } catch (error) {
        console.info(error);
        Vue.$toast.error(`Ha ocurrido un error, intenta de nuevo`);
      }
    },

    /* ACCIONES DE EMAIL */
    async registrarEmail(state: any, payload: any): Promise<void> {
      const { idEvento, data } = payload;
      try {
        const email = await RegistrarEmail(idEvento, data);
        console.info(email);
        if (email.status === 200) {
          Vue.$toast.success(`Email registrado con éxito`);
        }
      } catch (error) {
        console.info(error);
        Vue.$toast.error(`Ha ocurrido un error, intenta de nuevo`);
      }
    },
    async eliminarEmail(state: any, payload: any): Promise<void> {
      const { idEvento, idEmail } = payload;
      try {
        const email = await EliminarEmail(idEvento, idEmail);
        console.info(email);
        if (email.status === 200) {
          Vue.$toast.success(`Email eliminado con éxito`);
        }
      } catch (error) {
        console.info(error);
        Vue.$toast.error(`Ha ocurrido un error, intenta de nuevo`);
      }
    },

    /* ACCIONES DE FACTURACION */
    async registrarFacturacion(state: any, payload: any): Promise<void> {
      const { idEvento, data } = payload;
      try {
        const facturacion = await RegistrarFacturacion(idEvento, data);
        console.info(facturacion);
        if (facturacion.status === 200) {
          Vue.$toast.success(`Facturación registrada con éxito`);
        }
      } catch (error) {
        console.info(error);
        Vue.$toast.error(`Ha ocurrido un error, intenta de nuevo`);
      }
    },

    definirFacturacion({ commit }: any, payload: any): void {
      commit("definirFacturacion", payload);
    },
    definirEmails({ commit }: any, payload: any): void {
      commit("definirEmails", payload);
    },
    definirEvento({ commit }: any, payload: any): void {
      commit("definirEvento", payload);
    }
  },
  getters: {
    obtenerGastoTotal: (state: { evento: { gastos: Array<any> } }) => {
      const reducer = (acc: number, current: any) =>
        acc + parseFloat(current.monto);

      if (state.evento.gastos.length > 0) {
        const total = state.evento.gastos.reduce(reducer, 0);
        return total;
      }
    }
  }
};
export default eventoState;

/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "@/services/base.service";

const resource = "adjuntos";

export const ObtenerAdjuntos = async (idEvento: number): Promise<any> => {
  return await axios.get(`${resource}/${idEvento}`);
};

export const RegistrarAdjunto = async (
  idEvento: number,
  data: any
): Promise<any> => {
  return await axios.post(`${resource}/${idEvento}`, data);
};


















import { Component, Vue } from "vue-property-decorator";
import Navigation from "@/components/shared/Navigation.vue";
import NavigationMobile from "@/components/shared/NavigationMobile.vue";
import { Action } from "vuex-class";

@Component({
  components: {
    Navigation,
    NavigationMobile
  }
})
export default class Home extends Vue {
  @Action obtenerCondominios!: Function;
  private screenWidth = 0;

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.obtenerCondominios();
  }
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }
  private handleResize() {
    this.screenWidth = window.innerWidth;
  }
}

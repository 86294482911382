var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","elevation":"4"}},[_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headers,"sort-by":"fecha","items":_vm.eventosSinEliminados,"items-per-page":5,"light":""},on:{"click:row":_vm.mostrarEvento},scopedSlots:_vm._u([{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatearFecha(item.fecha))+" ")]}},{key:"item.equipo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.equipo.nombre)+" ")]}},{key:"item.mantenedor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mantenedor.nombre)+" ")]}},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.obtenerEstadoColor(item.estado),"small":"","dark":""}},[_vm._v(" "+_vm._s(item.estado)+" ")])]}},{key:"item.prioridad",fn:function(ref){
var item = ref.item;
return [_c('p',{class:_vm.obtenerPrioridadColor(item.prioridad)},[_vm._v(" "+_vm._s(item.prioridad)+" ")])]}},{key:"item.retraso",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.definirRetraso(item.fecha))+" ")]}},{key:"item.culminacion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.culminacion ? _vm.formatearFecha(item.culminacion) : "No ha culminado")+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }